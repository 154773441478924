<template>
  <b-container fluid>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Apoderados</h4>
          </template>
          <template v-slot:headerAction>
            <b-button variant="primary" @click="$refs.modalTeacher.show()"
              >Crear</b-button
            >
          </template>
          <template v-slot:body>
            <b-row>
              <b-col lg="6" class="my-3">
                <b-form-group>
                  <b-input-group>
                    <b-form-input
                      id="filter-input"
                      :disabled="
                        !Array.isArray(rows) ||
                        (rows.length === 0 && isBusy === false)
                      "
                      v-model="filter"
                      type="search"
                      placeholder="Buscar apoderado"
                    ></b-form-input>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col md="12" class="table-responsive">
                <b-table
                  class="table mb-0 table-borderless"
                  :items="rows"
                  :fields="columns"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :filter="filter"
                  :busy="
                    !Array.isArray(rows) || rows.length === 0
                      ? true
                      : false || isBusy == true
                  "
                  @filtered="onFiltered"
                >
                  <template #table-busy>
                    <div class="text-center text-danger my-2">
                      <div v-if="isBusy">
                        <b-spinner type="grow" class="align-middle"></b-spinner>
                        <strong> Cargando datos...</strong>
                      </div>
                      <span class="h5" v-else>{{ $t("app.not-found") }}</span>
                    </div>
                  </template>

                  <template v-slot:cell(firstname)="data">
                    <span>{{ data.item.firstname }}</span>
                  </template>
                  <template v-slot:cell(lastname)="data">
                    <span>{{ data.item.lastname }}</span>
                  </template>
                  <template v-slot:cell(email)="data">
                    <span>{{ data.item.email }}</span>
                  </template>
                  <template v-slot:cell(action)="data">
                    <b-button
                      variant=" iq-bg-danger rounded"
                      size="sm"
                      @click="remove(data.item)"
                      ><i class="ri-delete-bin-line m-0"></i
                    ></b-button>
                  </template>
                </b-table>
                <b-pagination
                  v-if="totalRows > perPage"
                  class="mt-3"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  pills
                  size="sm"
                  align="center"
                ></b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <modal-form ref="modalTeacher" :module-type="'Apoderado'" />
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import modalForm from "./../UserModal.vue";

export default {
  name: "Representative",
  components: {
    modalForm,
  },
  mounted() {
    core.index();
    this.isBusy = false;
    this.totalRows = this.rows.length;
    this.onFiltered(this.rows);
    this.isBusy = false;
  },
  methods: {
    add() {
      const obj = this.default();
      this.rows.push(obj);
    },
    default() {
      return {
        id: this.rows.length,
        name: "",
        acronym: "",
        editable: false,
      };
    },
    submit(item) {
      item.editable = false;
    },
    remove(item) {
      const index = this.rows.indexOf(item);
      this.rows.splice(index, 1);
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
  data() {
    return {
      idEdit: null,
      course: {
        name: "",
        acronym: "",
      },
      filter: "",
      perPage: 10,
      currentPage: 1,
      totalRows: 1,
      isBusy: false,
      columns: [
        { label: "Nombre", key: "firstname", class: "text-left" },
        { label: "Apellido", key: "lastname", class: "text-left" },
        { label: "Correo", key: "email", class: "text-left" },
        { label: "Acción", key: "action", class: "text-center" },
      ],
      rows: [
        {
          id: 1,
          firstname: "Tiger Nixon",
          lastname: "Arica",
          email: "tiger@gmail.com",
        },
        {
          id: 2,
          firstname: "Garrett Winters",
          lastname: "Antofagasta",
          email: "tiger@gmail.com",
        },
        {
          id: 3,
          firstname: "Ashton Cox",
          lastname: "Antofagasta",
          email: "tiger@gmail.com",
        },
        {
          id: 4,
          firstname: "Cedric Kelly",
          lastname: "Antofagasta",
          email: "tiger@gmail.com",
        },
        {
          id: 5,
          firstname: "Airi Satou",
          lastname: "Caldera",
          email: "tiger@gmail.com",
        },
        {
          id: 6,
          firstname: "Tiger Nixon",
          lastname: "Caldera",
          email: "tiger@gmail.com",
        },

        {
          id: 7,
          firstname: "Airi Satou",
          lastname: "Codegua",
          email: "tiger@gmail.com",
          editable: false,
        },
        {
          id: 8,
          firstname: "Tiger Nixon",
          lastname: "Codegua",
          email: "tiger@gmail.com",
        },
        {
          id: 9,
          firstname: "Airi Satou",
          lastname: "Paillaco",
          email: "tiger@gmail.com",
        },
        {
          id: 10,
          firstname: "Tiger Nixon",
          lastname: "Paillaco",
          email: "tiger@gmail.com",
        },
        {
          id: 11,
          firstname: "Airi Satou",
          lastname: "Conchalí",
          email: "tiger@gmail.com",
        },
        {
          id: 12,
          firstname: "Tiger Nixon",
          lastname: "Conchalí",
          email: "tiger@gmail.com",
        },
        {
          id: 13,
          firstname: "Ashton Cox",
          lastname: "Cerrillos",
          email: "tiger@gmail.com",
        },
        {
          id: 14,
          firstname: "Cedric Kelly",
          lastname: "Guaitecas",
          email: "tiger@gmail.com",
        },
        {
          id: 15,
          firstname: "Ashton Cox",
          lastname: "Cerrillos",
          email: "tiger@gmail.com",
        },
        {
          id: 16,
          firstname: "Cedric Kelly",
          lastname: "Guaitecas",
          email: "tiger@gmail.com",
        },
      ],
    };
  },
};
</script>
